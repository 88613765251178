@font-face {
    font-family: "OpenSansHebrewCondensed-Bold";
    src: url("../css/fonts/OpenSansHebrewCondensed-Bold.eot");
    src: url("../css/fonts/OpenSansHebrewCondensed-Bold.eot?#iefix") format("embedded-opentype"),
         url("../css/fonts/OpenSansHebrewCondensed-Bold.woff") format("woff"),
         url("../css/fonts/OpenSansHebrewCondensed-Bold.ttf") format("truetype"),
         url("../css/fonts/OpenSansHebrewCondensed-Bold.svg#AvenirNext-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}
$font: 'Roboto', sans-serif;
.table-wrap{
  overflow: auto;
}
.sales-page .content-desc .table-sales{
  min-width: 700px;
}
@media (max-width: 1199px){
  .product-single-content .btn-wrap .share .share-list li{
    margin-right: 4px;
  }
  .product-single-content{
    padding-left: 35px;
  }
}
@media (max-width: 991px){
  .product-single-content .btn-wrap{
    flex-wrap: wrap;
  }
  .product-single-content .price{
    margin-bottom: 30px;
  }
  .product-single-content .btn-wrap .buy-now{
    margin-bottom: 20px;
  }
  .product-single-content .description{
    padding-bottom: 30px;
  }
  .product-page .product-page-single .product-slider-mini{
    margin-bottom: 30px;
  }
  .company-information{
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .content-page .left-part .left-menu{
    margin-bottom: 10px;
  }
  .pagination{
    margin-top: 10px;
    margin-bottom: 0;
  }
  .content-page .first-block__left-image{
    padding-bottom: 10px;
  }
  .product-content-list-item{
    width: 31%;
  }
  .content-desc{
    padding-left: 20px;
    padding-bottom: 30px;
  }
  .content-desc .img-about{
    margin-top: 30px;
  }
  .create-item-list-filter .item{
    width: 25%;
    margin-bottom: 10px;
  }
  .create-item-list-filter{
    margin-bottom: 20px;
  }
  .create-item-page .content-desc .create-item-list-item{
    margin-bottom: 30px;
  }
}
@media (max-width: 767px){
  .product-single-content{
    padding-top: 30px;
    border: 0;
  }
  .content-page{
    background: white;
  }
  .product-page{
    background: #e2e0e0;
  }
  .product-single-content .btn-wrap .buy-now{
    margin-bottom: 0;
  }
  .product-single-content .price{
    margin-bottom: 10px;
  }
  .product-single-content .description{
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .charactiristic-list-item .desc ul{
    padding-left: 0;
  }
  .charactiristic-list-item{
    margin-bottom: 12px;
  }
  #user-info-form .button-center{
    margin-top: 20px;
  }
  .product-page .product-page-single .product-slider-wrap{
    padding-left: 0;
  }
  .product-page .product-page-single .product-slider-mini{
    padding-left: 0;
  }
  .product-page .product-page-single .message{
    margin-left: 0;
  }
  .product-single-content .list-param{
    margin-bottom: 15px;
  }
  .company-information-input .title{
    font-size: 22px;
  }
  .create-item-page .content-desc .create-item-list-item .left-block{
    width: 100%;
    margin-bottom: 10px;
    padding-right: 0;
  }
  .sales-page .content-desc{
    padding-right: 20px;
  }
  .create-item-page .content-desc .create-item-list-item .right-block{
    width: 100%;
  }
  .create-item-page .content-desc .description{
    padding-right: 10px;
  }
  .create-item-page .content-desc h1{
    margin-bottom: 10px;
  }
  .content-page .left-part {
    padding-left: 10px;
  }
  .content-page .left-part .left-menu{
    margin-bottom: 15px;
  }
  .content-desc{
    padding: 20px;
  }
  .company-information{
    padding-bottom: 20px;
    text-align: center;
  }
  .content-desc h1{
    margin-bottom: 10px;
  }
  .content-page .left-part{
    padding-top: 20px;
  }
  .product-content-list-item{
    width: 48%;
  }
  .content-page .first-block .text{
    padding-top: 25px;
  }
  .content-page .left-part .search-category{
    padding: 5px 17px;
  }
}
@media (max-width: 480px){
  .product-content-list-item__image{
    height: auto;
  }
  .product-content-list-item__image img{
    top: 0;
    left: 0;
    transform:  translate(0,0);
  }
  .first-block-title{
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .product-page .product-page-single .product-slider-mini li{
    width: 33%;
  }
  .product-page .product-page-single .product-slider-wrap .arrows.next-arrow{
    right: 5px;
  }
  .product-page .product-page-single .product-slider-wrap .arrows.prev-arrow{
    left: 5px;
  }
  .product-page .product-page-single .product-slider-mini{
    margin-bottom: 10px;
  }
  .user-info .face{
    width: 100%;
    margin-bottom: 10px;
    padding-top: 0;
  }
  .company-information-input .title{
    font-size: 18px;
  }
  .user-info .face-image{
    margin-bottom: 20px;
  }
  .company-information-input .input-wrap .title-input,
  .company-information-input .input-wrap .input{
    text-align: left;
    width: 100%;
  }
  .user-info-content{
    width: 100%;
  }
  .create-item-list-filter .item{
    width: 50%;
  }
  .content-page .left-part .category-list > li > a  {
    font-size: 13px;
  }
  .product-content{
    padding: 20px 30px;
    width: 100%;
  }
  .create-item-list-filter{
    margin-bottom: 0;
  }
  .create-item-page .content-desc .create-item-list-item{
    margin-bottom: 10px;
  }
  .logo-wrap{
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  .login-wrap-mobile{
    width: 100%;
  }
  .header .login-wrap{
    text-align: center;
  }
  .content-page .first-block .text h1{
    font-size: 34px;
  }
  .content-page .first-block .text .desc{
    font-size: 24px;
  }
  .content-page .left-part{
    padding-top: 20px;
  }
  .product-content-form{
    margin-bottom: 15px;
  }
  .product-content-list-item{
    width: 100%;
  }
  .banner-wrap{
    width: 100%;
  }
  .content-page .first-block .banner{
    text-align: center;
  }
  .text-wrap-first{
    width: 65%;
  }
  .left-image-wrap{
    width: 35%;
  }
  .yelow-btn{
    margin-right: 4px;
    font-size: 13px;
  }
  .content-page .first-block__left-image{
    padding-top: 25px;
  }
  .registration-popup .form{
    padding: 20px 30px;
  }
  .product-content-form{
    max-width: 100%;
  }
  .left-part-wrap{
    width: 100%;
    margin-bottom: 15px;
  }
}
