@font-face
	font-family: "svgfont"
	src: url('fonts/svgfont.eot')
	src: url('fonts/svgfont.eot?#iefix') format('eot'), url('fonts/svgfont.woff') format('woff'), url('fonts/svgfont.woff2') format('woff2'), url('fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-arrow: "\EA01"
$icon-arrow_cat: "\EA02"
$icon-cart-btn: "\EA03"
$icon-cart: "\EA04"
$icon-comments: "\EA05"
$icon-man: "\EA06"
$icon-menu: "\EA07"
$icon-phone: "\EA08"
$icon-search: "\EA09"
$icon-arrow-select: "\EA0A"
$icon-star: "\EA0B"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-arrow:before
	content: $icon-arrow
.icon-arrow_cat:before
	content: $icon-arrow_cat
.icon-cart-btn:before
	content: $icon-cart-btn
.icon-cart:before
	content: $icon-cart
.icon-comments:before
	content: $icon-comments
.icon-man:before
	content: $icon-man
.icon-menu:before
	content: $icon-menu
.icon-phone:before
	content: $icon-phone
.icon-search:before
	content: $icon-search
.icon-arrow-select:before
	content: $icon-arrow-select
.icon-star:before
	content: $icon-star