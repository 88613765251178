body {
	border: 0;
	margin: 0;
	padding: 0;
}
p{
	line-height: normal;
}
h1{
	margin: 0;
}
img{
	max-width: 100%;
	height: auto;
}
body .mfp-close-btn-in .mfp-close{
	display: none;
}
.header{
	background-color: #000000;
	padding: 15px 0;
	.login-wrap{
		text-align: right;
		@at-root .yelow-btn{
			color: #000000;
			font-family: 'Raleway', sans-serif;
			font-size: 14px;
			font-weight: 500;
			display: inline-block;
			vertical-align: middle;
			background-color: #ffdd00;
			padding: 6px 10px;
			margin-right: 15px;
			border: 0;
			transition: all .2s ease;
			&:hover{
				background-color: #ffffff;
				color: #000000;
				text-decoration: none;
			}
		}
		.login-btn{
			display: inline-block;
			vertical-align: middle;
			font-family: "Roboto";
			color: #ffffff;
			font-size: 14px;
			font-weight: 300;
			.icon{
				display: inline-block;
				vertical-align: middle;
				margin-right: 2px;
			}
			.login,
			.register{
				display: inline-block;
				vertical-align: middle;
				color: #ffffff;
				transition: all .3s ease;
				&:hover{
					color: #ffdd00;
					text-decoration: none;
				}
			}
		}
	}
}
.content-page{
	background: #1e5799; /* Old browsers */
	background: -moz-linear-gradient(to right, #ffffff 0%,#ffffff 50%, #e5e5e5 51%,#e5e5e5 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(to right, #ffffff 0%,#ffffff 50%, #e5e5e5 51%,#e5e5e5 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #ffffff 0%,#ffffff 50%, #e5e5e5 51%,#e5e5e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
	.first-block{
		background: #514f50;
		&__left-image{
			padding: 35px 0 50px 0;
		}
		.text{
			padding-top: 95px;
			text-align: center;
			h1{
				font-family: "Roboto";
				font-size: 44px;
				color: #ffffff;
				font-weight: 700;
				text-transform: uppercase;
			}
			.desc{
				font-family: "Roboto";
				font-size: 27px;
				font-weight: 300;
				color: #ffffff;
			}
		}
		.banner{
			text-align: right;
		}
	}
	.left-part{
		background-color: #ffffff;
		padding-top: 35px;
		.left-menu{
			padding-left: 0;
			list-style: none;
			margin-bottom: 35px;
			li{
				a{
					background: white;
					display: block;
					font: 14px $font;
					font-weight: 300;
					padding: 3px 0 3px 20px;
					color: #000000;
					transition: all .2s ease;
					&:hover{
						background-color: #ffdd00;
						text-decoration: none;
					}
					&.active{
						background-color: #ffdd00;
						font-weight: 500;
					}
				}
			}
		}
		.left-menu-two{
			list-style: none;
			padding-left: 0;
			li{
				a{
					display: block;
					padding: 3px 0 3px 20px;
					color: #000000;
					font: 14px $font;
					font-weight: 500;
					letter-spacing: 0.3px;
					transition: all .2s ease;
					&:hover{
						background-color: #ffdd00;
						text-decoration: none;
					}
					&.active{
						background-color: #ffdd00;
						font-weight: 500;
					}
				}
			}
		}
		.category-list{
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			> li{
				> a{
					color: #000000;
					font: 14px $font;
					font-weight: 500;
					display: inline-block;
					margin-bottom: 10px;
					position: relative;
					&:before{
						content: '>';
						position: absolute;
						color: #5c5c5c;
						font-family: "Raleway";
						font-size: 10px;
						left: -12px;
						transform: rotate(-90deg);
						transition: all .2s ease;
					}
					&.active{
						&:before{
							transform: rotate(0);
						}
					}
				}
				ul{
					padding-left: 0;
					list-style: none;
					display: none;
					li{
						margin-bottom: 5px;
						&:last-of-type{
							margin-bottom: 0;
						}
						input[type="checkbox"]{
							display: none;
							& + label{
								padding-left: 15px;
							 color: #000000;
							 font: 14px $font;
							 font-weight: 300;
							 cursor: pointer;
							 position: relative;
							 &:after{
								 content: '';
								 position: absolute;
								 top: 50%;
								 margin-top: -4px;
								 left: 0;
								 border: 1px solid #999999;
								 background-color: #ffffff;
								 height: 8px;
								 width: 8px;
							 }
						 }
							&:checked{
								& + label{
									font-weight: 500;
									&:after{
										background-color: #ffdd00;
									}
								}
							}
						}
					}
				}
			}
		}
		.search-category{
			display: inline-block;
			color: #000000;
			font: 14px $font;
			font-weight: 500;
			background-color: #ffdd00;
			padding: 5px 30px;
			line-height: 1;
			transition: all .2s ease;
			&:hover{
				 background-color: #000000;
				 color: white;
				 text-decoration: none;
			}
		}
	}
}
.product-content{
	background-color: #e5e5e5;
	padding: 20px 50px;
	&-form{
		width: 265px;
		margin-bottom: 30px;
		.input-wrap{
			position: relative;
			.search-input{
				padding-left: 12px;
				width: 100%;
				height: 27px;
				border: 0;
				box-shadow: -1px 1px 0px 2px rgba(182,182,182,1);
		    -webkit-box-shadow: -1px 1px 0px 2px rgba(182,182,182,1);
		    -moz-box-shadow: -1px 1px 0px 2px rgba(182,182,182,1);
		    box-shadow: -1px 1px 0px 2px rgba(182,182,182,1);
				padding-right: 30px;
				&:focus{
					outline: none;
				}
			}
			.search-button{
				position: absolute;
				top: 0;
				right: 0;
				background: white;
				height: 100%;
				border: 0;
				transition: all .2s ease;
				width: 27px;
				img{
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
				}
				&:hover{
					background: #ffdd00;
				}
			}
		}
	}
}
.product-content-list{
	font-size: 0;
	margin: 0 -15px;
	&-item{
		width: 23%;
		display: inline-block;
		vertical-align: top;
		margin: 0 1% 20px;
		position: relative;
		&__image{
			height: 170px;
			overflow: hidden;
			img{
				width: 100%;
				position: relative;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				transition: all .2s ease;
			}
		}
		&-content{
			background: white;
			box-shadow: 0px 0px 3px 0 #e0e0e0;
			padding: 4px;
			.title{
				min-height: 26px;
				font: 12px $font;
				font-weight: 300;
				margin-bottom: 2px;
				color: #000000;
			}
			.price{
				color: #000000;
				font: 16px $font;
				font-weight: 700;
			}
		}
		.hover{
			position: absolute;
			opacity: 0;
			visibility:hidden;
			top: 0;
			left: 0;
			color: #ffffff;
			font: 16px $font;
			font-weight: 300;
			background: rgba(0,0,0,0.8);
			width: 100%;
			height: 100%;
			text-align: center;
			transition: all .2s ease;
			.text{
				transition: all .2s ease;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-200%);
			}
		}
		&:hover{
			text-decoration: none;
			.hover{
				opacity: 1;
				visibility: visible;
				.text{
					transform: translate(-50%,-50%);
				}
			}
		}
	}
}
.footer{
	background-color: #000000;
	padding: 25px 0;
	text-align: center;
	.social-network{
		list-style: none;
		padding-left: 0;
		margin-bottom: 20px;
		font-size: 0;
		margin-bottom: 20px;
		li{
			display: inline-block;
			margin-right: 8px;
			transition: all .5s ease;
			&:hover{
				transform: rotatey(360deg);
			}
			&:last-of-type{
				margin-right: 0;
			}
		}
	}
	.footer-menu{
		font-size: 0;
		margin-bottom: 0;
		li{
			display: inline-block;
			margin-right: 25px;
			&:last-of-type{
				margin-right: 0;
			}
			a{
				font: 14px $font;
				color: #ffffff;
				font-weight: 500;
				transition: all .2s ease;
				&:hover{
					color: #ffdd00;
					text-decoration: none;
				}
			}
		}
	}
}
.product-page{
	background: #e2e0e0;
	.product-page-single{
		padding: 24px 0;
		.product-slider-wrap{
			padding-left: 20px;
			position: relative;
			margin-bottom: 15px;
			.arrows{
				position: absolute;
				width: 45px;
				height: 45px;
				border-radius: 50%;
				top: 50%;
				margin-top: -22.5px;
				text-align: center;
				line-height: 45px;
				background: rgba(255,255,255,0.21);
				transition: all .2s ease;
				cursor: pointer;
				z-index: 11;
				&:hover{
					background: rgba(255,255,255,1);
				}
				&.prev-arrow{
					left: 15px;
					img{
						transform: rotate(180deg);
					}
				}
				&.next-arrow{
					right: 15px;
				}
			}
			.product-slider{
				.slide{
					img{
						width: 100%;
					}
				}
			}
		}
		.product-slider-mini{
			padding-left: 20px;
			margin: 0 -6px;
			font-size: 0;
			list-style: none;
			margin-bottom: 120px;
			li{
				display: inline-block;
				vertical-align: top;
				padding: 0 6px;
				width: 25%;
				margin-bottom: 10px;
			}
		}
		.message{
			background-color: #696875;
			padding: 34px 50px 44px 132px;
			position: relative;
			text-align: center;
			margin-left: 20px;
			.title{
				color: #ffffff;
				font-family: "Raleway";
				font-size: 21px;
				font-weight: 700;
			}
			.desc{
				color: #ffffff;
				font-family: "Raleway";
				font-size: 21px;
				font-weight: 500;
				margin-bottom: 15px;
			}
			.mail{
				font-family: "Roboto";
				font-size: 14px;
				font-weight: 500;
				text-decoration: underline;
				color: #ffffff;
				transition: all .2s ease;
				&:hover{
					color: #ffdd00;
					text-decoration: none;
				}
			}
			.image{
				position: absolute;
				top: 6px;
				left: 24px;
				max-height: 100%;
			}
		}
	}
}
.first-block-title{
	background-image: url("../img/banners.png");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 56px 0 50px;
	h1{
		margin-bottom: 5px;
		color: #ffffff;
		font: 29px $font;
		text-transform: uppercase;
		font-weight: 700;
	}
	.desc{
		color: #ffffff;
		font: 22px $font;
		font-weight: 300;
	}
}
.product-single-content{
	border-left: 1px solid #a9a9a9;
	padding-left: 50px;
	padding-top: 85px;
	.title{
		color: #000000;
		font-family: "Roboto";
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	.list-param{
		list-style: none;
		padding-left: 0;
		margin-bottom: 25px;
		li{
			position: relative;
			color: #4a4a4a;
			margin-bottom: 16px;
			font-family: "Roboto";
			font-size: 14px;
			font-weight: 300;
			&:last-of-type{
				margin-bottom: 0;
			}
			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: -28px;
				width: 19px;
				height: 17px;
				background-image: url("../img/ok.png");
				background-repeat: no-repeat;
			}
		}
	}
	.price{
		color: #000000;
		opacity: 0.9;
		font-family: "Roboto";
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 55px;
	}
	.btn-wrap{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 25px;
		.buy-now{
			display: inline-block;
			vertical-align: bottom;
			background-color: #f8dc30;
			font-family: "Roboto";
			padding: 9px 71px;
			font-size: 14px;
			font-weight: 500;
			color: #000000;
			transition: all .2s ease;
			&:hover{
				background-color: #000000;
				color: #f8dc30;
				text-decoration: none;
			}
		}
		.share{
			display: inline-block;
			vertical-align: bottom;
			&__title{
				font-family: "Roboto";
				color: #000000;
				font-size: 14px;
				font-weight: 300;
				margin-bottom: 5px;
			}
			.share-list{
				padding-left: 0;
				list-style: none;
				font-size: 0;
				margin-bottom: 0;
				li{
					display: inline-block;
					vertical-align: top;
					margin-right: 8px;
					transition: all 1s ease;
					&:hover{
						transform: rotatey(360deg);
					}
					&:last-of-type{
						margin-right: 0;
					}
				}
			}
		}
	}
	.description{
		color: #000000;
		font-size: 12px;
		font-family: "Roboto";
		line-height: 22px;
		font-weight: 300;
		padding-bottom: 70px;
		border-bottom: 1px solid #969696;
		margin-bottom: 30px;
		.title{
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 0;
		}
	}
}
.charactiristic-list{
	padding-left: 0;
	&-item{
		font-size: 0;
		margin-bottom: 15px;
		&:last-of-type{
			margin-bottom: 0;
		}
		.title{
			width: 50%;
			display: inline-block;
			vertical-align: top;
			color: #000000;
			font-family: "Roboto";
			font-size: 14px;
			font-weight: 500;
		}
		.desc{
			width: 50%;
			display: inline-block;
			vertical-align: top;
			ul{
				list-style: none;
				margin-bottom: 0;
				li{
					color: #000000;
					font-family: "Roboto";
					font-size: 12px;
					font-weight: 300;
					margin-bottom: 2px;
					a{
						color: #000000;
						text-decoration: underline;
					}
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
.content-desc{
	min-height: calc(100vh - 172px);
	background-color: #e5e5e5;
	padding: 30px 8px 60px 50px;
	h1{
		color: #006699;
		font: 24px $font;
		font-weight: 500;
		margin-bottom: 15px;
	}
	h2{
		margin: 0;
		color: #006699;
		font: 14px $font;
		font-weight: 500;
	}
	p{
		color: #000000;
		font: 12px $font;
		font-weight: 300;
		strong{
			font: 12px $font;
			font-weight: 500;
		}
	}
	.img-about{
		margin-top: 80px;
	}
}
.company-information{
	background-color: #e5e5e5;
	padding-top: 25px;
	.title{
		color: #000000;
		font: 12px $font;
		font-weight: 500;
		margin-bottom: 10px;
		text-decoration: underline;
	}
	&-links{
		padding-left: 0;
		list-style: none;
		li{
			margin-bottom: 10px;
			a{
				color: #006699;
				font: 12px $font;
				text-decoration: underline;
				font-weight: 500;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
	.company-information-list{
		padding-left: 0;
		list-style: none;
		margin-bottom: 10px;
		li{
			font: 12px $font;
			margin-bottom: 2px;
		}
	}
	.mail{
		color: #006699;
		font: 12px $font;
		text-decoration: underline;
	}
	.social-network{
		list-style: none;
		padding-left: 0;
		margin-bottom: 15px;
		li{
			margin-bottom: 5px;
			&:last-of-type{
				margin-bottom: 0;
			}
			a{
				img{
					display: inline-block;
					vertical-align: middle;
					margin-right: 8px;
					position: relative;
					left: 0;
					transition: all .2s ease;
				}
				span{
					display: inline-block;
					vertical-align: middle;
					color: #006699;
					text-decoration: underline;
				}
				&:hover{
					text-decoration: none;
					img{
						left: 6px;
					}
				}
			}
		}
	}
}
.sales-page{
	.content-desc{
		padding-right: 45px;
		.top-sales{
			padding-bottom: 10px;
			margin-bottom: 5px;
			border-bottom: 1px solid #666666;
		}
		h1{
			color: #000000;
			font: 24px $font;
			font-weight: 300;
			float: left;
			margin-bottom: 0;
		}
		.total{
			float: right;
			margin-top: 8px;
			color: #000000;
			font: 16px $font;
			font-weight: 500;
		}
		.table-sales{
			width: 100%;
			thead{
				tr{
					background-color: #ffdd00;
					th{
						text-align: center;
						padding: 5px;
						color: #000000;
						font: 14px $font;
						font-weight: 500;
					}
				}
			}
			tbody{
				tr{
					border-bottom: 1px solid #666666;
					td{
						padding: 5px 0;
						text-align: center;
						&:first-of-type,
						&:nth-child(2){
							text-align: left;
						}
						&:first-of-type{
							width: 74px;
							padding-right: 10px;
						}
						strong{
							color: #000000;
							font: 12px $font;
							font-weight: 500;
						}
						&.name{
							text-align: left;
							color: #000000;
							font: 12px $font;
							font-weight: 300;
							strong{
								font-weight: 500;
							}
						}
						&.buyer{
							color: #000000;
							font: 12px $font;
							font-weight: 500;
						}
						&.date{
							font: 12px $font;
							font-weight: 300;
							color: #000000;
							strong{
								font-weight: 500;
							}
						}
					}
				}
			}
		}
	}
}
.pagination{
	ul{
		padding-left: 0;
		list-style: none;
		margin-bottom: 0;
		font-size: 0;
		li{
			display: inline-block;
			margin-right: 3px;
			a{
				color: #000000;
				font: 12px $font;
				font-weight: 300;
				transition: all .2s ease;
				&:hover,
				&.active{
					font-weight: 500;
					text-decoration: none;
				}
			}
		}
	}
}
.items-page{
	.product-content{
		h1{
			padding-bottom: 12px;
			border-bottom: 1px solid #666666;
			margin-bottom: 14px;
			color: #000000;
			font: 24px $font;
			font-weight: 300;
		}
		.hover{
			height: auto;
			bottom: 30px;
		}
		.sutotal{
			color: #000000;
			font: 14px $font;
			letter-spacing: 0.35px;
			font-weight: 500;
			margin-bottom: 14px;
		}
		.price{
			.value{
				float: left;
			}
			@at-root .edit-wrap{
				float: right;
				.item{
					display: inline-block;
					vertical-align: middle;
					color: #000000;
					font: 12px $font;
					font-weight: 300;
					&.edit{
						padding-left: 3px;
						border-left: 1px solid #000000;
						a{
							color: #000000;
						}
					}
					&.pending{
						color: #ff0000;
					}
					&.approved{
						color: #00cc00;
					}
				}
			}
		}
	}
}
#user-info-form{
	.yelow-btn{
		font-family: $font;
		width: 209px;
		max-width: 100%;
	}
	.button-center{
		text-align: center;
		margin-top: 60px;
	}
}
.user-info{
	font-size: 0;
	margin-top: 25px;
	.face{
		display: inline-block;
		vertical-align: middle;
		width: 31%;
		padding-top: 20px;
		text-align: center;
		.yelow-btn{
			text-align: center;
			width: 100%;
		}
		&-image{
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.75);
			height: 100px;
			width: 100px;
			margin: 0 auto 35px;
			background-color: #cccccc;
			border: 3px solid #ffffff;
			border-radius: 50%;
			position: relative;
			overflow: hidden;
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}
	&-content{
		display: inline-block;
		vertical-align: middle;
		width: 69%;
		font-size: 10px;
		padding-left: 15px;
		.input-wrap{
			margin-bottom: 10px;
			input{
				width: 100%;
				border: 1px solid #cdcdcd;
				background-color: #ffffff;
				border-radius: 2px;
				height: 30px;
				padding-left: 8px;
				color: #000000;
				font: 12px $font;
				font-weight: 300;
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				  opacity: 1;
				}
				&::-moz-placeholder { /* Firefox 19+ */
				  opacity: 1;
				}
				&:-ms-input-placeholder { /* IE 10+ */
				  opacity: 1;
				}
				&:-moz-placeholder { /* Firefox 18- */
				  opacity: 1;
				}
			}
		}
	}
	.input-wrap{
		input[type="file"]{
			display: none;
		}
	}
}
.company-information-input{
	padding-top: 30px;
	.title{
		color: #000000;
		font: 24px $font;
		font-weight: 300;
		border-bottom: 1px solid #666666;
		margin-bottom: 25px;
	}
	.input-wrap{
		font-size: 0;
		margin-bottom: 15px;
		.title-input{
			display: inline-block;
			vertical-align: middle;
			padding-right: 10px;
			text-align: right;
			width: 34%;
			span{
				display: inline-block;
				vertical-align: middle;
				color: #000000;
				font: 12px $font;
				font-weight: 300;
			}
			img{
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px;
			}
		}
		.input{
			display: inline-block;
			vertical-align: middle;
			width: 64%;
			input{
				height: 31px;
				width: 100%;
				border-radius: 2px;
				border: 1px solid #cdcdcd;
				background-color: #ffffff;
				padding-left: 10px;
				font: 13px $font;
				color: black;
			}
		}
	}
}
.create-item-page{
	.input-wrap-ckecbox{
		margin-bottom: 25px;
		input{
			display: none;
			& + label{
				color: #000000;
				font: 12px $font;
				font-weight: 300;
				padding-left: 27px;
				position: relative;
				&:after{
					top: 50%;
					margin-top: -10.5px;
					left: 0;
					content: '';
					position: absolute;
					width: 21px;
					height: 21px;
					border: 1px solid #000000;
					background-color: #ffffff;
				}
				&:before{
					content: '';
					position: absolute;
					top: 50%;
					left: 3px;
					margin-top: -7.5px;
					width: 15px;
					height: 15px;
					border-radius: 50%;
					z-index: 1;
					transition: all .2s ease;
					background-color: transparent;
					transform: scale(0);
				}
			}
			&:checked + label{
				&:before{
					background-color: #ffdd00;
					transform: scale(1);
				}
			}
		}
	}
	.btn{
		.btn-submit{
			width: 209px;
			background-color: #ffdd00;
			text-align: center;
			color: #000000;
			font: 14px $font;
			font-weight: 500;
			padding: 7px 3px;
			border: 0;
			&:focus{
				outline: none;
			}
		}
	}
	.content-desc{
		h1{
			margin-bottom: 30px;
			float: none;
			&:after{
				display: none;
			}
		}
		.description{
			padding-right: 270px;
			padding-bottom: 20px;
			border-bottom: 1px solid #666666;
			margin-bottom: 20px;
			color: #666666;
			line-height: 16px;
		}
		.create-item-list{
			&-item{
				font-size: 0;
				margin-bottom: 55px;
				.left-block{
					display: inline-block;
					vertical-align: top;
					padding-right: 18px;
					width: 50%;
					.text{
						margin-bottom: 20px;
						color: #000000;
						font: 12px $font;
						font-weight: 300;
						line-height: 17.74px;
					}
					.input-file{
						input{
							display: none;
							& + label{
								background-color: #ffdd00;
								padding: 5px 25px 5px 9px;
								img{
									margin-right: 15px;
									display: inline-block;
									vertical-align: middle;
								}
								span{
									color: #000000;
									font: 14px $font;
									font-weight: 500;
									display: inline-block;
									vertical-align: middle;
								}
							}
						}
					}
					.number{
						color: #000000;
						font: 36px $font;
						margin-bottom: 6px;
						font-weight: 500;
						letter-spacing: 1.22px;
						line-height: 1;
					}
					.sub_title{
						color: #000000;
						margin-bottom: 20px;
						font: 14px $font;
						font-weight: 500;
					}
					.input-wrap{
						margin-bottom: 20px;
						&:last-of-type{
							margin-bottom: 0;
						}
						input{
							width: 100%;
							height: 31px;
							border-radius: 2px;
							border: 1px solid #cdcdcd;
							background-color: #ffffff;
							padding-left: 8px;
							color: #000000;
							font: 12px $font;
							font-weight: 300;
							&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
							  opacity: 1;
							}
							&::-moz-placeholder { /* Firefox 19+ */
							  opacity: 1;
							}
							&:-ms-input-placeholder { /* IE 10+ */
							  opacity: 1;
							}
							&:-moz-placeholder { /* Firefox 18- */
							  opacity: 1;
							}
						}
					}
				}
				.right-block{
					display: inline-block;
					vertical-align: top;
					width: 50%;
					textarea{
						height: 159px;
						border-radius: 2px;
						border: 1px solid #cdcdcd;
						background-color: #ffffff;
						padding-left: 8px;
						padding-top: 10px;
						resize: none;
						width: 100%;
						color: #000000;
						font-family: "Raleway";
						font-size: 12px;
						font-weight: 300;
						&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
							opacity: 1;
						}
						&::-moz-placeholder { /* Firefox 19+ */
							opacity: 1;
						}
						&:-ms-input-placeholder { /* IE 10+ */
							opacity: 1;
						}
						&:-moz-placeholder { /* Firefox 18- */
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
.create-item-list-filter{
	padding-top: 30px;
	margin-bottom: 50px;
	.title{
		color: #000000;
		font: 14px $font;
		font-weight: 500;
		margin-bottom: 10px;
	}
	.item{
		display: inline-block;
		vertical-align: top;
		width: 16%;
	}
	.create-item-list-filter-list{
		list-style: none;
		padding-left: 0;
		li{
			margin-bottom: 6px;
			input{
				display: none;
				& + label{
					font: 12px $font;
					font-weight: 300;
					color: #000000;
					position: relative;
					margin-bottom: 0;
					padding-left: 14px;
					line-height: normal;
					&:after{
						content: '';
						position: absolute;
						top: 1px;
						left: 0;
						width: 10px;
						height: 10px;
						border: 1px solid #999999;
					}
					&:before{
						content: '';
						position: absolute;
						top: 3px;
						left: 2px;
						background-color: transparent;
						width: 6px;
						height: 6px;
						transition: all .2s ease;
					}
				}
				&:checked + label{
					font-weight: 500;
					&:before{
						background-color: #ffdd00;
					}
				}
			}
		}
	}
}
.filter-country{
	width: 207px;
	.title{
		margin-bottom: 10px;
		padding-left: 12px;
		color: #000000;
		font: 14px $font;
		font-weight: 500;
	}
	ul{
		border: 1px solid #999999;
		padding: 12px;
		background-color: #ffffff;
		margin-bottom: 0;
		height: 225px;
		overflow: auto;
		position: relative;
		li{
			margin-bottom: 5px;
			&:last-of-type{
				margin-bottom: 0;
			}
			input{
				display: none;
				& + label{
					position: relative;
					padding-left: 21px;
					color: #000000;
					font: 12px $font;
					font-weight: 300;
					&:after{
						position: absolute;
						top: 2px;
						left: 0;
						content: '';
						width: 10px;
						height: 10px;
						border: 1px solid #999999;
						background-color: #ffffff;
					}
					&:before{
						content: '';
						position: absolute;
						top: 4px;
						left: 2px;
						background-color: transparent;
						width: 6px;
						height: 6px;
						transition: all .2s ease;
						z-index: 1;
					}
				}
				&:checked + label{
					font-weight: bold;
					&:before{
						background-color: #ffdd00;
					}
				}
			}
		}
	}
}
.registration-popup{
	width: 100%;
	max-width: 549px;
	margin: auto;
	position: relative;
	.close-popup{
		position: absolute;
		top: 9px;
		right: 8px;
		transition: all .2s ease;
		cursor: pointer;
		&:hover{
			transform: rotate(180deg);
		}
	}
	.header{
		background-color: #ffffff;
		padding: 25px 3px 22px 3px;
		text-align: center;
		.title{
			margin-bottom: 14px;
			color: #000000;
			font: 24px $font;
			font-weight: 300;
		}
		.sub-title{
			color: #000000;
			font: 16px $font;
			font-weight: 300;
		}
	}
	.form{
		background-color: #dadbdd;
		padding: 20px 130px;
		.forgotten{
			input + label{
				float: left;
			}
			.forgotten__link{
				color: #2263ff;
				font: 12px $font;
				font-weight: 300;
				float: right;
			}
		}
		.input-wrap{
			margin-bottom: 12px;
			input{
				width: 100%;
				border: 1px solid #999999;
				background-color: #ffffff;
				height: 27px;
				padding-left: 4px;
				color: #666666;
				font: 12px $font;
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				  opacity: 1;
				}
				&::-moz-placeholder { /* Firefox 19+ */
				  opacity: 1;
				}
				&:-ms-input-placeholder { /* IE 10+ */
				  opacity: 1;
				}
				&:-moz-placeholder { /* Firefox 18- */
				  opacity: 1;
				}
			}
			input[type="checkbox"]{
				display: none;
				& + label{
					color: #666666;
					padding-left: 18px;
					font: 12px $font;
					font-weight: 300;
					position: relative;
					cursor: pointer;
					&:after{
						content: '';
						position: absolute;
						top: 1px;
						left: 0;
						width: 10px;
						height: 10px;
						border: 1px solid #000000;
						background-color: #ffffff;
					}
					&:before{
						content: '';
						position: absolute;
						top: 3px;
						left: 2px;
						width: 6px;
						height: 6px;
						background-color: transparent;
						transition: all .2s ease;
						z-index: 1;
						transform: scale(0);
					}
					a{
						color: #2263ff;
					}
				}
				&:checked{
					& + label{
						&:before{
							background-color: #ffdd00;
							transform: scale(1);
						}
					}
				}
			}
			.message{
				color: #666666;
				font: 12px $font;
				font-weight: 300;
			}
		}
		.btn-wrap{
			.btn{
				color: #000000;
				padding: 10px 3px;
				text-align: center;
				background-color: #ffdd00;
				display: inline-block;
				border: 0;
				width: 100%;
				font: 14px $font;
				font-weight: 500;
				margin-bottom: 8px;
				transition: all .2s ease;
				&:hover{
					background: #000000;
					color: white;
				}
			}
			.or{
				display: block;
				text-align: center;
				color: #666666;
				font: 14px $font;
				font-weight: 300;
				margin-bottom: 7px;
			}
			.btn-facebook{
				background-color: #003592;
				width: 100%;
				display: inline-block;
				padding: 12px 30px;
				text-align: center;
				color: #fefefe;
				font: 14px $font;
				font-weight: 500;
				position: relative;
				img{
					position: absolute;
					top: 4px;
					left: 14px;
					transition: all .2s ease;
				}
				&:hover{
					text-decoration: none;
					img{
						left: 30px;
					}
				}
			}
		}
	}
}
input.error{
	border-color: crimson!important;
}
.mfp-move-from-top {

  .mfp-content {
   vertical-align:top;
  }

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s;

    transform: translateY(-100px);
  }

  &.mfp-bg {
    opacity: 0;
	  transition: all 0.2s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateY(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: translateY(-50px);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}
.mfp-3d-unfold {


  .mfp-content {
    perspective: 2000px;
  }

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform-style: preserve-3d;
    transform: rotateY(-60deg);
  }


  &.mfp-bg {
    opacity: 0;
	  transition: all 0.5s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: rotateY(0deg);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: rotateY(60deg);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}
